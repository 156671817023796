/**
 * The offer popup module.
 *
 * @return {void}
 */
export const offerPopup = () => {
  const $triggers = document.querySelectorAll(".js-offer-popup-trigger");
  const $container = document.querySelector(".js-offer-popup-container");
  const $closeTriggers = document.querySelectorAll(".js-offer-popup-close");
  const $popups = $container.querySelectorAll(".js-offer-popup");

  // Reveal popups
  $triggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      $container.classList.remove("hidden");

      $popups.forEach((popup) => {
        popup.classList.add("hidden");

        if (popup.dataset.title == trigger.dataset.title) {
          popup.classList.remove("hidden");
        }
      });
    });
  });

  // Hide popups on background click
  $container.addEventListener("click", () => {
    $container.classList.add("hidden");

    $popups.forEach((popup) => {
      popup.classList.add("hidden");
    });
  });

  // Hide popups on close button click
  $closeTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      $container.classList.add("hidden");

      $popups.forEach((popup) => {
        popup.classList.add("hidden");
      });
    });
  });
};

// Export the module
export default offerPopup;
