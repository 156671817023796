import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
/*
SplitText.min.js is a Club GreenSock perk

Sign up at https://greensock.com/club or try them for free on CodePen or CodeSandbox
*/

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollSmoother);

/*
  Functions for opening and closing the header menu on desktop
*/
export const scrollSmootherFunction = (element) => {

  const scrollToTriggers = document.querySelectorAll(".js-smooth-scroll-to");
  const $aosContainerDesktop = document.querySelector(".aos-container-desktop");

  // create the scrollSmoother before your scrollTriggers
  const smoother = ScrollSmoother.create({
    content: element,
    smooth: 0.35,               // how long (in seconds) it takes to "catch up" to the native scroll position
    effects: true,           // looks for data-speed and data-lag attributes on elements 
    smoothTouch: 0,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    ease: 'sine.out',
  });

  if (scrollToTriggers) {
    scrollToTriggers.forEach(trigger => {
      trigger.addEventListener("click", (e) => {
        const targetElement = document.getElementById(trigger.dataset.target);
        smoother.scrollTo(targetElement, true, "top, 70px");
      });
    });
  }

  // if ($aosContainerDesktop) {
  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".aos-container",
  //       toggleActions: "play none none none",
  //     },
  //     delay: 0.25,
  //   });

  //   tl.fromTo(".aos-item", { opacity: 0, x: -20 }, { opacity: 1, x: 0, ease: "power1.out", stagger:0.25 });
  // }

  if ($aosContainerDesktop) {
    if (window.innerWidth > 768){
      const $aosElements = $aosContainerDesktop.querySelectorAll(".aos-item");
      $aosElements.forEach(element => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            toggleActions: "play none none none",
          },
          delay: 0.25,
        });
    
        tl.fromTo(element, { opacity: 0, x: -20 }, { opacity: 1, x: 0, ease: "power1.out" });
      });
    }
  }
};

// Export the module
export default scrollSmootherFunction;





