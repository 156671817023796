/*
  Functions for opening and closing the header menu on desktop
*/
export const filterEventsMobile = ($filter) => {
  const $events = document.querySelectorAll(".js-event-card");
  // Hide cards
  $events.forEach((card) => {
    card.classList.add("hidden");
  });

  // Reveal relevant cards
  $events.forEach((card) => {
    const cardDate = card.dataset.date;
    if (cardDate.includes($filter)) {
      card.classList.remove("hidden");
    }
  });
};

// Export the module
export default filterEventsMobile;
