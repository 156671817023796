/**
 * Functions for the search button.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const checkBoxes = () => {
  // Set elements
  const checkboxes = document.querySelectorAll(".js-booking-banner-checkbox");
  const $search = document.querySelector(".js-booking-banner-search");
  const $mobileBooking = document.querySelector(".js-mobile-booking-content");
  const $mobileSearch = document.querySelector(".js-mobile-booking-search");

  let checked = 0;

  checkboxes.forEach(box => {
    if (box.checked) {
      checked++;
    }
  });

  if (checked === 3) {
    if ($mobileBooking.classList.contains("mobile-booking-active")) {
      $mobileSearch.classList.remove('pointer-events-none');
      $mobileSearch.classList.remove('opacity-50');
    } else {
      $search.classList.remove('pointer-events-none');
      $search.classList.remove('opacity-50');
    }
  } else {
    if ($mobileBooking.classList.contains("mobile-booking-active")) {
      $mobileSearch.classList.add('pointer-events-none');
      $mobileSearch.classList.add('opacity-50');
    } else {
      $search.classList.add('pointer-events-none');
      $search.classList.add('opacity-50');
    }
  }
};

// Export the module
export default checkBoxes;
