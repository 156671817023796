

/**
 * The facility popup module.
 *
 * @return {void}
 */
export const urlRedirect = () => {
  const $section = document.querySelector(".js-facility-post");
  const $offers = document.querySelector(".js-offer-standard-trigger");
  const $headerAlert = document.querySelectorAll(".js-alert-book-now-button");
  const $about = document.querySelectorAll(".about-filter");
  let $bookNow, $bookingBanner;

  if (window.innerWidth < 1024) {
    $bookNow = document.querySelector(".js-book-now-button-mobile");
    $bookingBanner = document.querySelector(".js-mobile-booking-trigger");
  } else {
    $bookNow = document.querySelector(".js-book-now-button");
    $bookingBanner = document.querySelector(".js-booking-banner-accommodation-trigger");
  }

  // click booking banner if page loaded via book button
  if ($bookingBanner) {
    const book = sessionStorage.getItem("bookNow");

    if (book === "true") {
      if (document.readyState !== 'loading') {
        $bookingBanner.click();
        sessionStorage.setItem("bookNow", false);
      } else {
        document.addEventListener('DOMContentLoaded', function () {
          $bookingBanner.click();
          sessionStorage.setItem("bookNow", false);
        });
      }
    }
  }



  // facilities
  if ($section) {
    if ($section.dataset.type == 'food-and-drink') {
      window.location.href = "/food-and-drink";
    } else if ($section.dataset.type == 'leisure-activities') {
      window.location.href = "/leisure-activities";
    } else if ($section.dataset.type == 'indoor-activities') {
      window.location.href = "/indoor-activities";
    } else if ($section.dataset.type == 'outdoor-activities') {
      window.location.href = "/outdoor-activities";
    } else if ($section.dataset.type == 'amenities') {
      window.location.href = "/amenities";
    }
  }

  if ($offers) {
    if (window.location.href.includes("?type=latest-offers")) {
      $offers.click();
    }
  }

  if ($about) {
    $about.forEach(trigger => {
      if (window.location.href.includes(trigger.href)) {
        trigger.click();
      }
    });
  }

  // booking banner
  if ($bookNow) {
    $bookNow.addEventListener("click", () => {
      if ($bookingBanner) {
        $bookingBanner.click();
      } else {
        sessionStorage.setItem("bookNow", true);
        window.location.href = "/";
      }
    });
  }

  // booking banner
  if ($headerAlert) {
    $headerAlert.forEach(alert => {
      alert.addEventListener("click", () => {
        if ($bookingBanner) {
          $bookingBanner.click();
        } else {
          sessionStorage.setItem("bookNow", true);
          window.location.href = "/";
        }
      });

    });
  }
};

// Export the module
export default urlRedirect;
