/**
 * The facility popup module.
 *
 * @return {void}
 */
export const ownership = () => {
  // ownership tag filtering
  const ownershipFilters = document.querySelectorAll(".js-ownership-filter");
  const $sales = document.querySelector(".js-ownership-sales-trigger");
  const $tour = document.querySelector(".js-ownership-tour-trigger");

  if (ownershipFilters) {
    if (window.location.href.includes("?type")) {
      ownershipFilters.forEach((filter) => {
        if (window.location.href == filter.href) {
          filter.classList.add("bg-ocean-blue");
          filter.classList.add("text-white");
          filter.classList.add("pointer-events-none");
          filter.classList.remove("border-black");
          filter.classList.remove("hover:border-ocean-blue");
          filter.classList.remove("hover:bg-ocean-blue");
          filter.classList.remove("hover:text-white");
          filter.classList.remove("text-deep-grey");
        } else {
          filter.classList.remove("bg-ocean-blue");
          filter.classList.remove("text-white");
          filter.classList.remove("pointer-events-none");
          filter.classList.remove("border-ocean-blue");
          filter.classList.add("border-black");
          filter.classList.add("hover:border-ocean-blue");
          filter.classList.add("hover:bg-ocean-blue");
          filter.classList.add("hover:text-white");
          filter.classList.add("text-deep-grey");
        }
      });

      // estimated read time
      // const ownershipCards = document.querySelectorAll(".js-ownership-card");

      // ownershipCards.forEach((card) => {
      //   card.classList.add("hidden");
      //   const urlFilter = window.location.search;

      //   if (urlFilter.includes(card.dataset.type)) {
      //     card.classList.remove("hidden");
      //   }
      // });
    }
  }

  if ($sales) {
    $sales.addEventListener("click", () => {
      document.querySelector(".js-ownership-sales-target").click();
    });
  }

  if ($tour) {
    $tour.addEventListener("click", () => {
      document.querySelector(".js-ownership-tour-target").click();
    });
  }
};

// Export the module
export default ownership;
