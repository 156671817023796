import { app } from "./components/app/main.js";
import { forms } from "./components/forms/main.js";
import { modal } from "./components/modal/main.js";
import { helper } from "./components/helpers/main.js";
import toggleVisibility from "./components/toggle-visibility/main.js";
import animations from "./components/animations/main.js";
import faqToggle from "./components/faq-toggle/main.js";
import dropdownHover from "./components/dropdown-hover/main.js";
import facilityPopup from "./components/facility-popup/main.js";
import urlRedirect from "./components/url-redirects/main.js";
import imageZoom from "./components/image-zoom/main.js";
import eventFiltering from "./components/event-filtering/main.js";
import booking from "./components/booking/main.js";
import swipers from "./components/swipers/main.js";
import offerPopup from "./components/offer-popup/main.js";
import loadMore from "./components/load-more/main.js";
import mobileMenu from "./components/mobile-menu/main.js";
import blog from "./components/blog/main.js";
import ownership from "./components/ownership/main.js";
import galleryPopup from "./components/gallery-popup/main.js";
import menuPopup from "./components/menu-popup/main.js";
import accommodationImages from "./components/accommodation-images/main.js";
import programmePopup from "./components/programme-popup/main.js";
import accommodationVideo from "./components/accommodation-video/main.js";
import search from "./components/search/main.js";

// Sections
import OwnershipArchive from "./sections/ownership-archive/main.js";
import OwnershipPage from "./sections/ownership-page/main.js";
import OwnershipTemplate from "./sections/ownership-template/main.js";
import LandingBanner from "./sections/landing-banner/main.js";

app();
forms();
// modal();
toggleVisibility();
// animations();
faqToggle();
dropdownHover();
facilityPopup();
urlRedirect();
imageZoom();
eventFiltering();
booking();
swipers();
offerPopup();
loadMore();
mobileMenu();
blog();
ownership();
galleryPopup();
menuPopup();
accommodationImages();
programmePopup();
accommodationVideo();
search();

OwnershipArchive();
OwnershipPage();
OwnershipTemplate();
LandingBanner();

// This snippit is used to counteract the problem on iOS with the 100vh bug
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};

const heroHeight = () => {
  const doc = document.documentElement;

  let elementsHeight = 0;
  const $elements = document.querySelectorAll("header.header");

  $elements.forEach(($element) => {
    const boundingRect = $element.getBoundingClientRect();
    elementsHeight = elementsHeight + boundingRect.height;
  });
  let total = window.outerHeight - elementsHeight;
  doc.style.setProperty("--hero-height", `${total}px`);
};

const menuHeight = () => {
  const doc = document.documentElement;

  let elementsHeight = 0;
  const $elements = document.querySelectorAll("header.header");

  $elements.forEach(($element) => {
    const boundingRect = $element.getBoundingClientRect();
    elementsHeight = elementsHeight + boundingRect.height;
  });
  const total = window.innerHeight - elementsHeight;
  doc.style.setProperty("--menu-height", `${total}px`);
};

const headerHeight = () => {
  const doc = document.documentElement;
  let elementsHeight = 0;
  const $elements = document.querySelectorAll("header.header");

  $elements.forEach(($element) => {
    const boundingRect = $element.getBoundingClientRect();
    elementsHeight = elementsHeight + boundingRect.height;
  });

  const total = elementsHeight;
  doc.style.setProperty("--header-height", `${total}px`);
};

const $headerOwnership = document.querySelector(".header__ownership");
if ($headerOwnership) {
  const $ownershipLinks = $headerOwnership.querySelectorAll(
    ".ownership__links .ownership__link"
  );

  const $ownershipImages = $headerOwnership.querySelectorAll(
    ".ownership__images .ownership__image"
  );
  $ownershipLinks.forEach(($ownershipLink) => {
    $ownershipLink.addEventListener("mouseover", (event) => {
      $ownershipImages.forEach(($ownershipImage) => {
        if ($ownershipImage.dataset.type === $ownershipLink.dataset.type) {
          $ownershipImage.classList.remove("hidden");
        } else {
          $ownershipImage.classList.add("hidden");
        }
      });
    });
  });
}

window.addEventListener("resize", () => {
  appHeight();
  heroHeight();
  menuHeight();
  headerHeight();
});

window.addEventListener("scroll", () => {
  appHeight();
  heroHeight();
  menuHeight();
  headerHeight();
});
appHeight();
heroHeight();
menuHeight();
headerHeight();
