

/**
 * The facility popup module.
 *
 * @return {void}
 */
export const blog = () => {
   // blog tag filtering
   const blogFilters = document.querySelectorAll(".js-blog-filter");
 
   if (blogFilters){
    blogFilters.forEach(filter => {
      if (window.location.href.includes(filter.href)){
        filter.classList.add("bg-ocean-blue");
        filter.classList.add("text-white");
        filter.classList.add("pointer-events-none");
        filter.classList.remove("border-black");
        filter.classList.remove("hover:border-ocean-blue");
        filter.classList.remove("hover:bg-ocean-blue");
        filter.classList.remove("hover:text-white");
        filter.classList.remove("text-deep-grey");
      } else {
        filter.classList.remove("bg-ocean-blue");
        filter.classList.remove("text-white");
        filter.classList.remove("pointer-events-none");
        filter.classList.remove("border-ocean-blue");
        filter.classList.add("border-black");
        filter.classList.add("hover:border-ocean-blue");
        filter.classList.add("hover:bg-ocean-blue");
        filter.classList.add("hover:text-white");
        filter.classList.add("text-deep-grey");
      }
    });
  } 

  // estimated read time
  const blogCards = document.querySelectorAll(".js-blog-card");

  blogCards.forEach(card => {
    const label = card.querySelector(".js-read-time");
    const content = card.querySelector(".js-article-content").innerText;
    const wpm = 225;
    const words = content.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    label.innerText = `${time} min read`;
  });

};

// Export the module
export default blog;
