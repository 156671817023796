/**
 * The acommodation video popup module.
 *
 * @return {void}
 */
export const accommodationVideo = () => {
  const $triggers = document.querySelectorAll(
    ".js-accommodation-video-trigger"
  );
  const $container = document.querySelector(
    ".js-accommodation-video-container"
  );
  const $closeTriggers = document.querySelectorAll(
    ".js-accommodation-video-close"
  );

  // Reveal popups
  $triggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      $container.classList.remove("hidden");
    });
  });

  // Hide popups on close button click
  $closeTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      $container.classList.add("hidden");
    });
  });
};

// Export the module
export default accommodationVideo;
