import { zoom } from "./functions/zoom.js";
import { close } from "./functions/close.js";

/**
 * The accommodation swiper module.
 *
 * @return {void}
 */
export const imageZoom = () => {
  // Set the elements
  const $container = document.querySelectorAll(".js-image-zoom-container");
  // Check if any containers exists
  if ($container) {
    $container.forEach(container => {
      const zoomTrigger = container.querySelector(".js-image-zoom-start");
      const closeTrigger = container.querySelector(".js-image-zoom-stop");

      if (zoomTrigger){
        zoom(container);
      }

      if (closeTrigger){
        close(container);
      }
    });
  }
};

// Export the module
export default imageZoom;
