

/**
 * The faq toggle module.
 *
 * @return {void}
 */
export const faqToggle = () => {
  const $triggers = document.querySelectorAll(".js-faq");

  $triggers.forEach(trigger => {
    trigger.addEventListener("click", () => {
      const plus = trigger.querySelector(".js-faq-plus");
      const minus = trigger.querySelector(".js-faq-minus");
      const answer = trigger.querySelector(".js-faq-answer");

      plus.classList.toggle("hidden");
      minus.classList.toggle("hidden");
      answer.classList.toggle("hidden");
    });
  });
};

// Export the module
export default faqToggle;
