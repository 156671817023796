import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);


/**
 * The programme popup module.
 *
 * @return {void}
 */
export const programmePopup = () => {
  const $trigger = document.querySelector(".js-programme-popup-trigger");
  const $container = document.querySelector(".js-programme-popup-container");
  const $closeTriggers = document.querySelector(".js-programme-popup-close");
  const $popup = $container.querySelector(".js-programme-popup");
  const $zoomTrigger = $container.querySelector(".js-programme-popup-zoom");
  const zoomTarget = $container.querySelector('.js-programme-zoom-target');
  const body = document.getElementById("body");

  // Reveal popups
  if ($trigger) {
    $trigger.addEventListener("click", () => {
      $container.classList.remove("hidden");
      body.classList.add("h-full");
      body.classList.add("overflow-hidden");


      $popup.classList.remove("hidden");

      $zoomTrigger.addEventListener("click", () => {
        const zoomOpen = $zoomTrigger.querySelector(".zoom-open");
        const zoomClose = $zoomTrigger.querySelector(".zoom-close");
        const zoomImage = zoomTarget.querySelector(".programme-image");

        zoomTarget.classList.toggle("overflow-y-auto");
        zoomImage.classList.toggle("scale-150");
        zoomOpen.classList.toggle("hidden");
        zoomClose.classList.toggle("hidden");
      });
    });



    // Hide popups on background click
    // $container.addEventListener("click", () => {
    //   $container.classList.add("opacity-0");
    //   $container.classList.add("pointer-events-none");

    //   $popups.forEach(popup => {
    //     popup.classList.add("opacity-0");
    //     popup.classList.add("pointer-events-none");
    //   });
    // });

    // Hide popups on close button click
    $closeTriggers.addEventListener("click", () => {
      $container.classList.add("hidden");
      body.classList.remove("h-full");
      body.classList.remove("overflow-hidden");


      $popup.classList.add("hidden");
    });
  }
};

// Export the module
export default programmePopup;