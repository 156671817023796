

/**
 * The toggle visibility module.
 *
 * @return {void}
 */
export const toggleVisibility = () => {
  const $triggers = document.querySelectorAll(".js-toggle-visibility-trigger");
  const $lateOfferTrigger = document.querySelector(".js-offer-late-trigger");
  const $standardOfferTrigger = document.querySelector(".js-offer-standard-trigger");
  const $lateOffers = document.querySelectorAll(".js-offers-container-late-availability");
  const $standardOffers = document.querySelectorAll(".js-offers-container-standard-offer");

  $triggers.forEach(trigger => {
    trigger.addEventListener("click", () => {
      const container = trigger.closest(".js-toggle-visibility-container");
      const triggers = container.querySelectorAll(".js-toggle-visibility-trigger");
      const targets = container.querySelectorAll(".js-toggle-visibility-target");
      const target = document.getElementById(trigger.dataset.target);

      // Reset triggers
      triggers.forEach(element => {
        if (element.classList.contains("trigger-button")) {
          element.classList.remove("bg-ocean-blue");
          element.classList.remove("text-white");
          element.classList.remove("border-ocean-blue");
          element.classList.add("border-black");
        } else {
          element.classList.add("opacity-50");
        }
      });

      // Reset targets
      targets.forEach(element => {
        element.classList.add("hidden");
      });

      // Reveal trigger & target
      if (trigger.classList.contains("trigger-button")) {
        trigger.classList.add("bg-ocean-blue");
        trigger.classList.add("text-white");
        trigger.classList.remove("opacity-50");
        trigger.classList.remove("border-black");
        trigger.classList.add("border-ocean-blue");
      } else {
        trigger.classList.remove("opacity-50");
      }

      target.classList.remove("hidden");
    });
  });

  if ($lateOfferTrigger) {
    $lateOfferTrigger.addEventListener("click", () => {
      // Update active button
      $lateOfferTrigger.classList.add("bg-ocean-blue");
      $lateOfferTrigger.classList.add("text-white");
      $lateOfferTrigger.classList.remove("hover:bg-ocean-blue");
      $lateOfferTrigger.classList.remove("bg-transparent");
      $lateOfferTrigger.classList.add("border-ocean-blue");
      $lateOfferTrigger.classList.remove("border-black");
      $lateOfferTrigger.classList.remove("hover:text-white");
      $lateOfferTrigger.classList.remove("text-deep-grey");

      // Update deactive button
      $standardOfferTrigger.classList.remove("bg-ocean-blue");
      $standardOfferTrigger.classList.remove("text-white");
      $standardOfferTrigger.classList.add("hover:bg-ocean-blue");
      $standardOfferTrigger.classList.add("hover:text-white");
      $standardOfferTrigger.classList.add("bg-transparent");
      $standardOfferTrigger.classList.remove("border-ocean-blue");
      $standardOfferTrigger.classList.add("border-black");
      $standardOfferTrigger.classList.add("text-deep-grey");

      // Hide & reveal offers
      $lateOffers.forEach(offer => {
        offer.classList.remove("hidden");
      });

      $standardOffers.forEach(offer => {
        offer.classList.add("hidden");
      });
    });
  }

  if ($standardOfferTrigger) {
    $standardOfferTrigger.addEventListener("click", () => {
      // Update active button
      $standardOfferTrigger.classList.add("bg-ocean-blue");
      $standardOfferTrigger.classList.add("text-white");
      $standardOfferTrigger.classList.remove("hover:bg-ocean-blue");
      $standardOfferTrigger.classList.remove("hover:text-white");
      $standardOfferTrigger.classList.remove("bg-transparent");
      $standardOfferTrigger.classList.add("border-ocean-blue");
      $standardOfferTrigger.classList.remove("border-black");
      $standardOfferTrigger.classList.remove("text-deep-grey");

      // Update deactive button
      $lateOfferTrigger.classList.remove("bg-ocean-blue");
      $lateOfferTrigger.classList.remove("text-white");
      $lateOfferTrigger.classList.add("hover:bg-ocean-blue");
      $lateOfferTrigger.classList.add("hover:border-ocean-blue");
      $lateOfferTrigger.classList.add("bg-transparent");
      $lateOfferTrigger.classList.remove("border-ocean-blue");
      $lateOfferTrigger.classList.add("border-black");
      $lateOfferTrigger.classList.add("hover:text-white");
      $lateOfferTrigger.classList.add("text-deep-grey");

      // Hide & reveal offers
      $lateOffers.forEach(offer => {
        offer.classList.add("hidden");
      });

      $standardOffers.forEach(offer => {
        offer.classList.remove("hidden");
      });
    });
  }
};

// Export the module
export default toggleVisibility;
