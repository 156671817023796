import { Swiper, Scrollbar, Pagination, Navigation, EffectFade } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);
Swiper.use([EffectFade]);
/**
 * Initialize the containers.
 *
 * @param  {object}  $container  The brands.
 * @return {void}
 */
export const initializeLateAvailability = ($container) => {
  // Add a content loaded event listener to the document
  document.addEventListener("DOMContentLoaded", (event) => {
    // Create a new swiper instance
    // eslint-disable-next-line no-new
    const offers = new Swiper(".js-late-availability-swiper", {
      slidesPerView: 1,
      spaceBetween: 8,
      initialSlide: 0,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      centeredSlides: true,
      navigation: {
        nextEl: '.late-availability-swiper-button-next',
        prevEl: '.late-availability-swiper-button-prev',
      },
    });
  });
};

// Export the module
export default initializeLateAvailability;
