import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);
/**
 * Initialize the containers.
 *
 * @param  {object}  $container  The brands.
 * @return {void}
 */
export const initializeReview = ($container) => {
  // Add a content loaded event listener to the document
  document.addEventListener("DOMContentLoaded", (event) => {
    // Create a new swiper instance
    // eslint-disable-next-line no-new
    const review = new Swiper(".js-review-swiper", {
      slidesPerView: 1,
      spaceBetween: 32,
      navigation: {
        nextEl: ".review-swiper-button-next",
        prevEl: ".review-swiper-button-prev",
      },
      pagination: {
        el: ".js-review-swiper-pagination",
        clickable: true,
        type: "bullets",
        bulletElement: "div",
        bulletClass: "review-swiper-pagination-bullet",
        bulletActiveClass: "review-swiper-pagination-bullet-active",
      },
      breakpoints: {
        // when window width is >= 320px
        1072: {
          slidesPerView: 3,
        },
      },
    });
  });
};

// Export the module
export default initializeReview;
