import Litepicker from "litepicker";
import checkBoxes from "./checkboxes";

/**
 * Functions for the date selector.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const dateSelector = ($trigger) => {
  // Set the elements
  let select,
    accommodationSelect,
    accommodationTrigger,
    accommodationChevron,
    guestsSelect,
    guestsTrigger,
    guestsChevron,
    bookingReset,
    searchTrigger,
    durationContainer,
    label,
    calendar,
    isMobile,
    dates,
    nightsNum;

  if (window.innerWidth < 1024) {
    select = document.querySelector(".js-booking-banner-date-select-mobile");
    bookingReset = document.querySelector(".js-booking-banner-reset-mobile");
    searchTrigger = document.querySelector(".js-mobile-booking-search");
    label = document.querySelector(".js-booking-banner-date-label-mobile");
    calendar = document.querySelector(".js-booking-banner-calendar-mobile");
    isMobile = true;
    dates = document.querySelector(".js-booking-banner-nights-mobile");
    durationContainer = document.querySelector(
      ".js-booking-banner-durations-mobile"
    );

    // Accommodation dropdown
    accommodationSelect = document.querySelector(
      ".js-booking-banner-accommodation-select-mobile"
    );
    accommodationTrigger = document.querySelector(
      ".js-booking-banner-accommodation-trigger-mobile"
    );
    accommodationChevron = accommodationTrigger.querySelector(
      ".js-booking-banner-chevron"
    );

    // Guest dropdown
    guestsSelect = document.querySelector(
      ".js-booking-banner-guests-select-mobile"
    );
    guestsTrigger = document.querySelector(
      ".js-booking-banner-guests-trigger-mobile"
    );
    guestsChevron = guestsTrigger.querySelector(".js-booking-banner-chevron");
  } else {
    select = document.querySelector(".js-booking-banner-date-select");
    bookingReset = document.querySelector(".js-booking-banner-reset");
    searchTrigger = document.querySelector(".js-booking-banner-search");
    label = document.querySelector(".js-booking-banner-date-label");
    calendar = document.querySelector(".js-booking-banner-calendar");
    dates = document.querySelector(".js-booking-banner-nights");
    durationContainer = document.querySelector(".js-booking-banner-durations");

    // Accommodation dropdown
    accommodationSelect = document.querySelector(
      ".js-booking-banner-accommodation-select"
    );
    accommodationTrigger = document.querySelector(
      ".js-booking-banner-accommodation-trigger"
    );
    accommodationChevron = accommodationTrigger.querySelector(
      ".js-booking-banner-chevron"
    );

    // Guest dropdown
    guestsSelect = document.querySelector(".js-booking-banner-guests-select");
    guestsTrigger = document.querySelector(".js-booking-banner-guests-trigger");
    guestsChevron = guestsTrigger.querySelector(".js-booking-banner-chevron");
  }

  const options = accommodationSelect.querySelectorAll(
    ".js-booking-banner-accommodation-type"
  );
  const durations = select.querySelectorAll(".js-booking-banner-duration");
  const dateCheckbox = document.getElementById("date-checkbox");
  const background = document.querySelector(".js-booking-banner-background");
  const guestsCheckbox = document.getElementById("guests-checkbox");
  const accommodationCheckbox = document.getElementById(
    "accommodation-checkbox"
  );
  const nightsIncrease = dates.querySelector(
    ".js-booking-banner-nights-increase"
  );
  const nightsDecrease = dates.querySelector(
    ".js-booking-banner-nights-decrease"
  );
  const nightsCount = dates.querySelector(".js-booking-banner-nights-count");

  const date = new Date();
  const maxDate = select.dataset.maximumDate;

  const dateString = "20" + maxDate + " GMT";

  const maximum = Date.parse(dateString);


  // Dropdown functionality
  $trigger.addEventListener("click", () => {
    if ($trigger.classList.contains("select-active")) {
      $trigger.classList.remove("select-active");
      select.classList.add("hidden");
      select.classList.remove("z-modal");

      if (background) {
        background.classList.add("hidden");
      }
    } else {
      $trigger.classList.add("select-active");
      select.classList.remove("hidden");
      select.classList.add("z-modal");
      if (background) {
        background.classList.remove("hidden");
      }

      // Close accommodation dropdown
      accommodationTrigger.classList.remove("select-active");
      accommodationSelect.classList.add("hidden");
      accommodationChevron.classList.add("rotate-180");
      accommodationSelect.classList.remove("z-modal");


      // Close guests dropdown
      guestsTrigger.classList.remove("select-active");
      guestsSelect.classList.add("hidden");
      guestsSelect.classList.remove("z-modal");
      guestsChevron.classList.add("rotate-180");
    }
  });

  if (background) {
    background.addEventListener("click", () => {
      $trigger.classList.remove("select-active");
      $trigger.classList.add("bg-white");
      $trigger.classList.remove("bg-sky-blue");
      select.classList.add("hidden");
      select.classList.remove("z-modal");
      background.classList.add("hidden");
    });
  }

  let calendarContainer;

  if (window.innerWidth < 1024) {
    calendarContainer = document.querySelector(
      ".js-booking-banner-calendar-mobile"
    );
  } else {
    calendarContainer = document.querySelector(".js-booking-banner-calendar");
  }

  // Litepicker
  const picker = new Litepicker({
    element: calendarContainer,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();
      return [0, 1, 2, 3, 4, 6].includes(d) || [0, 1].includes(m);
    },
  });

  // Calendar options for any days nights
  const anyday = {
    element: calendarContainer,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const m = day.getMonth();

      return [0, 1].includes(m);
    },
  };

  // Calendar options for 3 nights
  const threeNights = {
    element: calendarContainer,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();

      return [0, 1, 2, 3, 4, 6].includes(d) || [0, 1].includes(m);
    },
  };

  // Calendar options for 4 nights
  const fourNights = {
    element: calendarContainer,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();

      return [0, 2, 3, 4, 5, 6].includes(d) || [0, 1].includes(m);
    },
  };

  // Calendar options for 7 nights
  const weeks = {
    element: calendarContainer,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();

      return [0, 2, 3, 4, 6].includes(d) || [0, 1].includes(m);
    },
  };

  // Calendar options for 7 nights
  const weeksWithSaturday = {
    element: calendarContainer,
    inlineMode: true,
    autoRefresh: true,
    minDate: date,
    maxDate: maximum,
    lockDaysFilter: (day) => {
      const d = day.getDay();
      const m = day.getMonth();

      return [0, 2, 3, 4].includes(d) || [0, 1].includes(m);
    },
  };

  // Duration selection
  durations.forEach((element) => {
    element.addEventListener("click", () => {
      // change inactive button styling
      durations.forEach((currentElement) => {
        currentElement.classList.add("text-deep-grey");
        currentElement.classList.remove("bg-ocean-blue");
        currentElement.classList.remove("border-ocean-blue");
        currentElement.classList.remove("text-white");
        currentElement.classList.add("border-black");
        currentElement.classList.remove("active-duration");
      });

      // change active button styling
      element.classList.remove("text-deep-grey");
      element.classList.add("bg-ocean-blue");
      element.classList.add("border-ocean-blue");
      element.classList.add("text-white");
      element.classList.remove("border-black");
      element.classList.add("active-duration");

      if (element.dataset.duration === "3") {
        picker.setOptions(threeNights);
        picker.clearSelection();
      } else if (element.dataset.duration === "4") {
        picker.setOptions(fourNights);
        picker.clearSelection();
      } else if (element.dataset.duration === "7") {
        const activeAccommodation = document.querySelector(
          ".active-accommodation-option"
        ).dataset.type;
        if (
          activeAccommodation == "lodges" ||
          activeAccommodation == "holiday-homes" ||
          activeAccommodation == "glamping" ||
          activeAccommodation == "cottage"
        ) {
          picker.setOptions(weeksWithSaturday);
        } else {
          picker.setOptions(weeks);
        }
        picker.clearSelection();
      } else if (element.dataset.duration === "14") {
        const activeAccommodation = document.querySelector(
          ".active-accommodation-option"
        ).dataset.type;
        if (
          activeAccommodation == "lodges" ||
          activeAccommodation == "holiday-homes" ||
          activeAccommodation == "glamping" ||
          activeAccommodation == "cottage"
        ) {
          picker.setOptions(weeksWithSaturday);
        } else {
          picker.setOptions(weeks);
        }
        picker.clearSelection();
      }

      dateCheckbox.checked = false;
      checkBoxes();
    });
  });

  picker.on("before:click", (target) => {
    if (
      target.classList.contains("day-item") &&
      target.classList.contains("is-locked") !== true
    ) {
      picker.setDateRange();
      dateCheckbox.checked = true;
      checkBoxes();

      delay(100).then(() => updateLabel());
      $trigger.classList.remove("bg-white");
      $trigger.classList.add("bg-sky-blue");
      if (bookingReset) {
        bookingReset.classList.remove("opacity-50");
        bookingReset.classList.remove("pointer-events-none");
      }

      if (isMobile) {
        guestsTrigger.click();
      }
    }
  });

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  // Update the label on date change
  const updateLabel = () => {
    const date = calendar.value;
    const currentAccommodation = document.querySelector(
      ".active-accommodation-option"
    ).dataset.type;

    if (
      currentAccommodation == "camping" ||
      currentAccommodation == "touring"
    ) {
      nightsNum = parseInt(nightsCount.innerText);
    } else {
      const duration = select.querySelector(".active-duration");

      nightsNum = duration.dataset.duration;
    }

    if (date != null) {
      label.innerHTML = `${nightsNum} nights from ${date}`;
    } else {
      label.innerHTML = `Select Duration and dates`;
    }
  };

  // Update available days on accommodation change
  options.forEach((option) => {
    option.addEventListener("click", () => {
      picker.clearSelection();

      if (
        option.dataset.type == "lodges" ||
        option.dataset.type == "lodges" ||
        option.dataset.type == "glamping" ||
        option.dataset.type == "cottage"
      ) {
        const $currentDuration = document.querySelector(
          ".js-booking-banner-duration.active-duration"
        );
        const durationValue = $currentDuration.dataset.duration;
        switch (durationValue) {
          case "3":

            picker.setOptions(threeNights);
            break;
          case "4":

            picker.setOptions(fourNights);
            break;
          case "7":

            picker.setOptions(weeksWithSaturday);
            break;
          case "14":

            picker.setOptions(weeksWithSaturday);
            break;
        }
      } else if (option.dataset.type == "touring") {
        picker.setOptions(anyday);
      } else if (option.dataset.type == "camping") {
        picker.setOptions(anyday);
      }
    });
  });

  if (nightsIncrease) {
    nightsIncrease.addEventListener("click", () => {
      const currentCount = parseInt(nightsCount.innerText);
      const date = calendar.value;

      if (currentCount < 14) {
        const newCount = currentCount + 1;
        nightsCount.innerText = newCount;

        if (label.innerHTML.includes("from")) {
          label.innerHTML = `${newCount} nights from ${date}`;
        } else {
          label.innerHTML = `${newCount} nights`;
        }
      }
    });
  }

  if (nightsDecrease) {
    nightsDecrease.addEventListener("click", () => {
      const currentCount = parseInt(nightsCount.innerText);
      const date = calendar.value;

      if (currentCount > 1) {
        const newCount = currentCount - 1;
        nightsCount.innerText = newCount;

        if (label.innerHTML.includes("from")) {
          if (newCount == 1) {
            label.innerHTML = `${newCount} night from ${date}`;
          } else {
            label.innerHTML = `${newCount} nights from ${date}`;
          }
        } else {
          if (newCount == 1) {
            label.innerHTML = `${newCount} night`;
          } else {
            label.innerHTML = `${newCount} nights`;
          }
        }
      }
    });
  }

  if (bookingReset) {
    // Reset functionality
    bookingReset.addEventListener("click", () => {
      // Reset date label
      $trigger.classList.add("bg-white");
      $trigger.classList.remove("bg-sky-blue");
      label.innerText = "Select Duration and Dates";

      // Reset accommodation label
      accommodationTrigger.classList.add("bg-white");
      accommodationTrigger.classList.remove("bg-sky-blue");
      accommodationTrigger.querySelector(
        ".js-booking-banner-accommodation-text"
      ).innerText = `Choose Accommodation`;

      // Reset guest label
      guestsTrigger.querySelector(
        ".js-booking-banner-guest-label"
      ).innerText = `Add Guests`;
      guestsTrigger.classList.add("bg-white");
      guestsTrigger.classList.remove("bg-sky-blue");

      // Reset accommodation selection
      options.forEach((currentOption) => {
        const currentEmpty = currentOption.querySelector(
          ".js-booking-banner-option-empty"
        );
        const currentChecked = currentOption.querySelector(
          ".js-booking-banner-option-selected"
        );

        currentOption.classList.remove("active-accommodation-option");
        currentEmpty.classList.remove("hidden");
        currentChecked.classList.add("hidden");
      });

      // Reset quantities
      document
        .querySelectorAll(".js-booking-banner-guests-quantity")
        .forEach((element) => {
          element.innerText = 0;
        });

      // Reset duration
      durations.forEach((currentElement) => {
        currentElement.classList.add("text-deep-grey");
        currentElement.classList.remove("bg-ocean-blue");
        currentElement.classList.remove("border-ocean-blue");
        currentElement.classList.remove("text-white");
        currentElement.classList.add("border-black");
        currentElement.classList.remove("active-duration");

        if (currentElement.dataset.duration === "3") {
          // change active button styling
          currentElement.classList.remove("text-deep-grey");
          currentElement.classList.add("bg-ocean-blue");
          currentElement.classList.add("border-ocean-blue");
          currentElement.classList.add("text-white");
          currentElement.classList.remove("border-black");
          currentElement.classList.add("active-duration");
        }
      });

      // Reset calendar
      picker.clearSelection();
      picker.setOptions(threeNights);
      picker.gotoDate(date);

      // Reset checkboxes
      accommodationCheckbox.checked = false;
      guestsCheckbox.checked = false;
      dateCheckbox.checked = false;

      // deactivate search
      searchTrigger.classList.add("opacity-50");
      searchTrigger.classList.add("pointer-events-none");

      // Deactivate button
      bookingReset.classList.add("opacity-50");
      bookingReset.classList.add("pointer-events-none");
    });
  }
};

// Export the module
export default dateSelector;
