import filterEvents from "./functions/filter-clicked";
import filterEventsMobile from "./functions/select-changed";
import resetCards from "./functions/view-all-clicked";


export const eventFiltering = () => {
  // Set elements
  const filters = document.querySelectorAll(".js-events-filter");
  const select = document.querySelector(".js-events-filter-select");
  const viewAll = document.querySelector(".js-events-filter-view-all");

  // Desktop filters
  if (filters) {
    filters.forEach(element => {
      element.addEventListener("click", () => {
        filterEvents(element);
      });
    });
  }

  // Mobile dropdown
  if (select){
    select.addEventListener("change", () => {
      filterEventsMobile(select.options[select.selectedIndex].text);
    });
  }

  // Mobile view all
  if (viewAll){
    viewAll.addEventListener("click", () => {
      resetCards();
    });
  }
  
  // URL queries
  const location = window.location.href;
  let month;

  if (location.includes("month=january")){
    month = "January"
  } else if (location.includes("month=february")){
    month = "February"
  } else if (location.includes("month=march")){
    month = "March"
  } else if (location.includes("month=april")){
    month = "April"
  } else if (location.includes("month=may")){
    month = "May"
  } else if (location.includes("month=june")){
    month = "June"
  } else if (location.includes("month=july")){
    month = "July"
  } else if (location.includes("month=august")){
    month = "August"
  } else if (location.includes("month=september")){
    month = "September"
  } else if (location.includes("month=october")){
    month = "October"
  } else if (location.includes("month=november")){
    month = "November"
  } else if (location.includes("month=december")){
    month = "December"
  } else {
    month = false;
  }

  // Click relevant filter
  filters.forEach(filter => {
    if (filter.dataset.month === month){
      filter.click();
    }
  });

  // Select relevant value
  if (month != false){
    select.value = month;

  }
};

// Export the module
export default eventFiltering;
