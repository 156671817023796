import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);
/**
 * Initialize the containers.
 *
 * @param  {object}  $container  The brands.
 * @return {void}
 */
export const initializeOffers = ($container) => {
  // Add a content loaded event listener to the document
  document.addEventListener("DOMContentLoaded", (event) => {
    // Create a new swiper instance
    // eslint-disable-next-line no-new
    const offers = new Swiper(".js-offers-swiper", {
      slidesPerView: 1,
      spaceBetween: 8,
      initialSlide: 0,
      centeredSlides: true,
      navigation: {
        nextEl: ".offers-swiper-button-next",
        prevEl: ".offers-swiper-button-prev",
      },
      breakpoints: {
        // when window width is < 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
          initialSlide: 0,
          centeredSlides: false,
        },
        // when window width is >= 1072px

        1072: {
          slidesPerView: 3.9,
          spaceBetween: 32,
          initialSlide: 1,
          centeredSlides: false,
        },
      },
    });
  });
};

// Export the module
export default initializeOffers;
