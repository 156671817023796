import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);
/**
 * Initialize the containers.
 *
 * @param  {object}  $container  The brands.
 * @return {void}
 */
export const initializeAccommodationModal = ($container) => {
  // Add a content loaded event listener to the document
  document.addEventListener("DOMContentLoaded", (event) => {
    // Create a new swiper instance
    // eslint-disable-next-line no-new
    const accommodation = new Swiper(".js-accommodation-modal-swiper", {
      slidesPerView: 1,
      spaceBetween: 32,
      centeredSlides: true,
      navigation: {
        nextEl: '.accommodation-modal-swiper-button-next',
        prevEl: '.accommodation-modal-swiper-button-prev',
      },
      pagination: {
        el: ".accommodation-modal-swiper-pagination",
        type: "fraction",
      },
    });
  });
};

// Export the module
export default initializeAccommodationModal;
