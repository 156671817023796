import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);

/**
 * The gallery popup module.
 *
 * @return {void}
 */
export const accommodationImages = () => {
  const $triggers = document.querySelectorAll(
    ".js-accommodation-images-trigger"
  );
  const $container = document.querySelector(
    ".js-accommodation-images-container"
  );
  const $background = document.querySelector(
    ".js-accommodation-gallery-background"
  );
  const $closeTriggers = document.querySelectorAll(
    ".js-accommodation-images-close"
  );
  let popup;

  // Reveal popups
  $triggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      $container.classList.remove("hidden");

      if (window.innerWidth > 1023) {
        popup = document.querySelector(".js-accommodation-gallery-desktop");

        const gallery = new Swiper(
          ".js-accommodation-gallery-modal-swiper-desktop",
          {
            slidesPerView: 1,
            spaceBetween: 32,
            centeredSlides: true,
            navigation: {
              nextEl: ".gallery-modal-swiper-button-next-desktop",
              prevEl: ".gallery-modal-swiper-button-prev-desktop",
            },
            pagination: {
              el: ".gallery-modal-swiper-pagination-desktop",
              type: "fraction",
            },
          }
        );
      } else {
        popup = document.querySelector(".js-accommodation-gallery-mobile");

        const gallery = new Swiper(
          ".js-accommodation-gallery-modal-swiper-mobile",
          {
            slidesPerView: 1,
            spaceBetween: 32,
            centeredSlides: true,
            navigation: {
              nextEl: ".gallery-modal-swiper-button-next-mobile",
              prevEl: ".gallery-modal-swiper-button-prev-mobile",
            },
            pagination: {
              el: ".gallery-modal-swiper-pagination-mobile",
              type: "fraction",
            },
          }
        );
      }

      popup.classList.remove("opacity-0");
      popup.classList.remove("h-0");
      popup.classList.add("h-full");
    });
  });

  // Hide popups on close button click
  $closeTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      $container.classList.add("hidden");
      popup.classList.add("opacity-0");
      popup.classList.add("h-0");
      popup.classList.remove("h-full");
    });
  });
};

// Export the module
export default accommodationImages;
