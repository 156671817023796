export const OwnershipPage = () => {
  const $ownershipPage = document.querySelector(".ownership-page");
  if ($ownershipPage) {
    const $headerOwnership = document.querySelector(".header__ownership");
    const $headerOwnershipMobile = document.querySelector(
      ".header__ownership__mobile"
    );

    const $ownershipContactForm = document.querySelector(
      ".ownership-contact-form"
    );

    if ($ownershipContactForm) {
      const $headerOwnershipEnquire = $headerOwnership?.querySelector(
        ".ownership__enquire"
      );

      const $headerOwnershipMobileEnquire = document?.querySelector(
        ".ownership__enquire__mobile"
      );

      if ($headerOwnershipEnquire) {
        $headerOwnershipEnquire.addEventListener("click", (event) => {
          event.preventDefault();
          $ownershipContactForm.scrollIntoView();
        });
      }

      if ($headerOwnershipMobileEnquire) {
        $headerOwnershipMobileEnquire.addEventListener("click", (event) => {
          event.preventDefault();
          $ownershipContactForm.scrollIntoView();
        });
      }
    }
  }
};

export default OwnershipPage;
