import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);
/**
 * Initialize the containers.
 *
 * @param  {object}  $container  The brands.
 * @return {void}
 */
export const initializeFacilityFeatures = ($container) => {
  // Add a content loaded event listener to the document
  document.addEventListener("DOMContentLoaded", (event) => {
    // Create a new swiper instance
    // eslint-disable-next-line no-new
    const features = new Swiper(".js-facility-features-swiper", {
      slidesPerView: 1,
      spaceBetween: 32,
      navigation: {
        nextEl: '.features-swiper-button-next',
        prevEl: '.features-swiper-button-prev',
      },
      breakpoints: {
        // when window width is < 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 20

        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      }
    });
  });
};

// Export the module
export default initializeFacilityFeatures;
