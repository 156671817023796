import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);
/**
 * Initialize the containers.
 *
 * @param  {object}  $container  The brands.
 * @return {void}
 */
export const initializeAccommodationImage = ($container) => {
  // Add a content loaded event listener to the document
  document.addEventListener("DOMContentLoaded", (event) => {
    // Create a new swiper instance
    // eslint-disable-next-line no-new
    const accommodation = new Swiper(".js-accommodation-image-swiper", {
      slidesPerView: 1,
      spaceBetween: 32,
      navigation: {
        nextEl: '.accommodation-swiper-button-next',
        prevEl: '.accommodation-swiper-button-prev',
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: 'bullets',
        bulletElement: "div",
        bulletClass: "accommodation-swiper-pagination-bullet",
        bulletActiveClass: "accommodation-swiper-pagination-bullet-active"
      },
    });
  });
};

// Export the module
export default initializeAccommodationImage;
