/**
 * The dropdown hover module.
 *
 * @return {void}
 */
export const dropdownHover = () => {
  const $triggers = document.querySelectorAll(".js-dropdown-hover-trigger");
  const $targets = document.querySelectorAll(".js-dropdown-hover-target");

  $triggers.forEach(trigger => {
    trigger.addEventListener("mouseover", () => {
      $targets.forEach(target => {
        target.classList.add("hidden");

        if (target.dataset.title == trigger.dataset.target ){
          target.classList.remove("hidden");
        }
      });
    });
  });
};

// Export the module
export default dropdownHover;
