import initializeAccommodationModal from "./accommodation-modal-swiper/functions/initialize.js";
import initializeGlobalAccommodation from "./accommodation-options-swiper/functions/initialize.js";
import initializeAccommodationImage from "./accommodation-swiper/functions/initialize.js";
import initializeFacilityFeatures from "./facility-features-swiper/functions/initialize.js";
import initializeInlineReview from "./inline-review-swiper/functions/initialize.js";
import initializeLateAvailability from "./late-availability-swiper/functions/initialize.js";
import initializeOffers from "./offers-swiper/functions/initialize.js";
import initializeParkReview from "./park-review-swiper/functions/initialize.js";
import initializeCardSwiper from "./product-card-swiper/functions/initialize.js";
import initializeReview from "./review-swiper/functions/initialize.js";
import initializeWhatsOn from "./whats-on-swiper/functions/initialize.js";

/**
 * The review swiper module.
 *
 * @return {void}
 */
export const swipers = () => {
  // Set the elements
  const $reviewSwiper = document.querySelector(".js-review-swiper");
  // Check if the swiper exists
  if ($reviewSwiper) {
    // Initialize the swiper
    initializeReview($reviewSwiper);
  }

  const $cardSwiper = document.querySelector(".js-card-swiper");
  // Check if the swiper exists
  if ($cardSwiper) {
    // Initialize the swiper
    initializeCardSwiper($cardSwiper);
  }

  // Set the elements
  const $parkReviewsSwiper = document.querySelector(".js-park-reviews-swiper");
  // Check if the swiper exists
  if ($parkReviewsSwiper) {
    // Initialize the swiper
    initializeParkReview($parkReviewsSwiper);
  }

  // Set the elements
  const $inlineReviewSwiper = document.querySelector(".js-inline-review-swiper");
  // Check if the swiper exists
  if ($inlineReviewSwiper) {
    // Initialize the swiper
    initializeInlineReview($inlineReviewSwiper);
  }

  // Set the elements
  const $globalAccommodationSwiper = document.querySelector(".js-accommodation-options-swiper");
  // Check if the swiper exists
  if ($globalAccommodationSwiper) {
    // Initialize the swiper
    initializeGlobalAccommodation($globalAccommodationSwiper);
  }

  // Set the elements
  const $facilitiesFeaturesSwiper = document.querySelector(".js-facility-features-swiper");
  // Check if the swiper exists
  if ($facilitiesFeaturesSwiper) {
    // Initialize the swiper
    initializeFacilityFeatures($facilitiesFeaturesSwiper);
  }

  // Set the elements
  const $accommodationImageSwiper = document.querySelector(".js-accommodation-image-swiper");
  // Check if the swiper exists
  if ($accommodationImageSwiper) {
    // Initialize the swiper
    initializeAccommodationImage($accommodationImageSwiper);
  }

  // Set the elements
  const $accommodationModalSwiper = document.querySelector(".js-accommodation-modal-swiper");
  // Check if the swiper exists
  if ($accommodationModalSwiper) {
    // Initialize the swiper
    initializeAccommodationModal($accommodationModalSwiper);
  }

  // Set the elements
  const $offerSwiper = document.querySelector(".js-offers-swiper");
  // Check if the swiper exists
  if ($offerSwiper) {
    // Initialize the swiper
    initializeOffers($offerSwiper);
  }

  // Set the elements
  const $whatsOnSwiper = document.querySelector(".js-whats-on-swiper");
  // Check if the swiper exists
  if ($whatsOnSwiper) {
    // Initialize the swiper
    initializeWhatsOn($whatsOnSwiper);
  }


  // Set the elements
  const $lateAvailabilitySwiper = document.querySelector(".js-late-availability-swiper");
  // Check if the swiper exists
  if ($lateAvailabilitySwiper) {
    // Initialize the swiper
    initializeLateAvailability($lateAvailabilitySwiper);
  }
};

// Export the module
export default swipers;
