import checkBoxes from "./checkboxes";

/**
 * Functions for the guests selector.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const guestsSelector = ($trigger) => {
  // Set the elements
  let select,
    dateSelect,
    dateTrigger,
    accommodationSelect,
    accommodationTrigger,
    accommodationChevron,
    bookingReset;
  if (window.innerWidth < 1024) {
    select = document.querySelector(".js-booking-banner-guests-select-mobile");
    bookingReset = document.querySelector(".js-booking-banner-reset-mobile");

    // Date dropdown
    dateSelect = document.querySelector(
      ".js-booking-banner-date-select-mobile"
    );
    dateTrigger = document.querySelector(
      ".js-booking-banner-date-trigger-mobile"
    );

    // Accommodation dropdown
    accommodationSelect = document.querySelector(
      ".js-booking-banner-accommodation-select-mobile"
    );
    accommodationTrigger = document.querySelector(
      ".js-booking-banner-accommodation-trigger-mobile"
    );
    accommodationChevron = accommodationTrigger.querySelector(
      ".js-booking-banner-chevron"
    );
  } else {
    select = document.querySelector(".js-booking-banner-guests-select");
    bookingReset = document.querySelector(".js-booking-banner-reset");

    // Date dropdown
    dateSelect = document.querySelector(".js-booking-banner-date-select");
    dateTrigger = document.querySelector(".js-booking-banner-date-trigger");

    // Accommodation dropdown
    accommodationSelect = document.querySelector(
      ".js-booking-banner-accommodation-select"
    );
    accommodationTrigger = document.querySelector(
      ".js-booking-banner-accommodation-trigger"
    );
    accommodationChevron = accommodationTrigger.querySelector(
      ".js-booking-banner-chevron"
    );
  }
  const chevron = $trigger.querySelector(".js-booking-banner-chevron");
  const options = select.querySelectorAll(".js-booking-banner-guests-type");
  const increaseTriggers = select.querySelectorAll(
    ".js-booking-banner-guests-increase"
  );
  const decreaseTriggers = select.querySelectorAll(
    ".js-booking-banner-guests-decrease"
  );
  const label = $trigger.querySelector(".js-booking-banner-guests-text");
  const checkbox = document.getElementById("guests-checkbox");
  const background = document.querySelector(".js-booking-banner-background");

  // Update the label on quantity change
  const updateTotal = () => {
    const label = $trigger.querySelector(".js-booking-banner-guest-label");
    const quantities = document.querySelectorAll(
      ".js-booking-banner-guests-quantity"
    );
    let quantity = 0;

    quantities.forEach((element) => {
      const value = parseInt(element.innerHTML);
      quantity = quantity + value;
    });

    if (quantity === 1) {
      label.innerHTML = `${quantity} Guest`;
    } else if (quantity === 0) {
      label.innerHTML = `Add Guests`;
    } else {
      label.innerHTML = `${quantity} Guests`;
    }
  };

  $trigger.addEventListener("click", () => {
    if ($trigger.classList.contains("select-active")) {
      $trigger.classList.remove("select-active");
      select.classList.add("hidden");
      chevron.classList.add("rotate-180");
      select.classList.remove("z-modal");
      if (background) {
        background.classList.add("hidden");
      }
    } else {
      $trigger.classList.add("select-active");
      select.classList.remove("hidden");
      chevron.classList.remove("rotate-180");
      select.classList.add("z-modal");
      if (background) {
        background.classList.remove("hidden");
      }

      // Close date dropdown
      dateTrigger.classList.remove("select-active");
      dateSelect.classList.add("hidden");
      dateSelect.classList.remove("z-modal");

      // Close accommodation dropdown
      accommodationTrigger.classList.remove("select-active");
      accommodationSelect.classList.add("hidden");
      accommodationChevron.classList.add("rotate-180");
      accommodationSelect.classList.remove("z-modal");
    }
  });

  if (background) {
    background.addEventListener("click", () => {
      $trigger.classList.remove("select-active");
      $trigger.classList.add("bg-white");
      $trigger.classList.remove("bg-sky-blue");
      select.classList.add("hidden");
      chevron.classList.remove("rotate-180");
      select.classList.remove("z-modal");
      background.classList.add("hidden");
    });
  }

  // Decrease quantity triggers
  decreaseTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const container = trigger.closest(".js-booking-banner-guests-type");
      const quantity = container.querySelector(
        ".js-booking-banner-guests-quantity"
      );
      const amount = parseInt(quantity.innerText);

      if (amount > 0) {
        quantity.innerText = amount - 1;
        updateTotal();
      }

      if (container.dataset.type == "adults" && amount == 1) {
        checkbox.checked = false;
        checkBoxes();
      }
    });
  });

  // Increase quantity triggers
  increaseTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const container = trigger.closest(".js-booking-banner-guests-type");
      const quantity = container.querySelector(
        ".js-booking-banner-guests-quantity"
      );
      const amount = parseInt(quantity.innerText);

      if ((container.dataset.type === "dogs" && amount === 2) === false) {
        quantity.innerText = amount + 1;
        updateTotal();
      }

      if (container.dataset.type == "adults") {
        checkbox.checked = true;
        $trigger.classList.remove("bg-white");
        $trigger.classList.add("bg-sky-blue");
        checkBoxes();
      }

      // Enable reset button
      if (bookingReset) {
        bookingReset.classList.remove("opacity-50");
        bookingReset.classList.remove("pointer-events-none");
      }
    });
  });
};

// Export the module
export default guestsSelector;
