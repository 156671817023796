export const zoom = ($container) => {

  const $trigger = $container.querySelector(".js-image-zoom-start");
  const $closeTrigger = $container.querySelector(".js-image-zoom-stop");
  const $target = $container.querySelector(".js-image-zoom-target");

  // Add a event handler for click events
  $trigger.addEventListener("click", (event) => {
    $target.classList.remove("pointer-events-none");
    $trigger.classList.add("hidden");
    $closeTrigger.classList.remove("hidden");

    if (window.innerWidth < 768) {
      const body = document.getElementById("body");
      body.classList.add("overflow-hidden");

      $target.style.transform = `scale(2)`;
    } else {
      $target.style.transform = `scale(1.5)`;
    }

    // desktop
    $target.addEventListener("mousemove", function (e) {
      let x = e.offsetX;
      let y = e.offsetY;
      $target.style.transformOrigin = `${x}px ${y}px`;
    });

    // mobile
    $target.addEventListener("touchmove", function (e) {
      let x = e.touches[0].clientX;
      let y = e.touches[0].clientY;
      $target.style.transformOrigin = `${x}px ${y}px`;
    });
  });
};

// Export the module
export default zoom;
