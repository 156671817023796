/*
  Functions for opening and closing the header menu on desktop
*/
export const filterEvents = ($filter) => {
  const $filters = document.querySelectorAll(".js-events-filter");
  const $events = document.querySelectorAll(".js-event-card");

  // Set inactive filter styling
  $filters.forEach(filter => {
    filter.classList.remove("border-deep-grey");
    filter.classList.remove("text-deep-grey");
    filter.classList.add("border-transparent");
    filter.classList.add("text-deep-grey-30");
  });

  // Set active filter styling
  $filter.classList.add("border-deep-grey");
  $filter.classList.add("text-deep-grey");
  $filter.classList.remove("border-transparent");
  $filter.classList.remove("text-deep-grey-30");

  // Hide cards
  $events.forEach(card => {
    card.classList.add("hidden");
  });

  // Reveal relevant cards
  $events.forEach(card => {
    const cardDate = card.dataset.date;
    const filterDate = $filter.dataset.month;

    if (filterDate == "all"){
      card.classList.remove("hidden");
    } else {
      if (cardDate.includes(filterDate)){
        card.classList.remove("hidden");
      }
    }
  });
};

// Export the module
export default filterEvents;





