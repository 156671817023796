import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);
/**
 * Initialize the containers.
 *
 * @param  {object}  $container  The brands.
 * @return {void}
 */
export const initializeGlobalAccommodation = ($container) => {
  // Add a content loaded event listener to the document
  document.addEventListener("DOMContentLoaded", (event) => {
    // Create a new swiper instance
    // eslint-disable-next-line no-new
    const accommodation = new Swiper(".js-accommodation-options-swiper", {
      slidesPerView: 1.2,
      spaceBetween: 8,
      initialSlide: 0,
      centeredSlides: false,
      navigation: {
        nextEl: ".accommodation-swiper-button-next",
        prevEl: ".accommodation-swiper-button-prev",
      },
      breakpoints: {
        // when window width is < 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
          initialSlide: 0,
        },
        // when window width is >= 1024px
        1072: {
          slidesPerView: 3,
          spaceBetween: 32,
          initialSlide: 0,
        },
      },
    });
  });
};

// Export the module
export default initializeGlobalAccommodation;
