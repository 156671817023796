/**
 * The programme popup module.
 *
 * @return {void}
 */
export const search = () => {
  // Desktop
  const trigger = document.querySelector(".search-trigger");
  const input = document.querySelector(".search-content");

  if (trigger && input) {
    trigger.addEventListener("click", () => {
      const query = input.value;
      window.location.href = window.location.origin + "/?s=" + query;
    });

    input.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        const query = input.value;
        window.location.href = window.location.origin + "/?s=" + query;
      }
    });
  }

  // Mobile
  const mobile = document.querySelector(".js-mobile-menu-flyout");
  const mobileTrigger = mobile?.querySelector(".search-trigger");
  const mobileInput = mobile?.querySelector(".search-content");

  if (mobileTrigger && mobileInput) {
    mobileTrigger.addEventListener("click", () => {
      const query = mobileInput.value;
      window.location.href = window.location.origin + "/?s=" + query;
    });

    mobileInput.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        const query = mobileInput.value;
        window.location.href = window.location.origin + "/?s=" + query;
      }
    });
  }
};

// Export the module
export default search;
