export const LandingBanner = () => {
  const $landingBanner = document.querySelector(".landing-banner");
  if ($landingBanner) {
    const $landingBannerForm = $landingBanner.querySelector(
      ".landing-banner__form"
    );
    const $formFirstname = $landingBannerForm.querySelector(".form__firstname");
    const $formLastname = $landingBannerForm.querySelector(".form__lastname");
    const $formEmail = $landingBannerForm.querySelector(".form__email");
    // const $formNewsletter =
    //   $landingBannerForm.querySelector(".form__newsletter");
    const $fromSubmit = $landingBannerForm.querySelector(".form__submit");
    const $formHoneypot = $landingBannerForm.querySelector("input[name=hp]");
    const $formPostId = $landingBannerForm.querySelector(".form__post");

    const $csrf = document.querySelector('meta[name="csrf"]');

    $landingBannerForm.addEventListener("submit", (event) => {
      event.preventDefault();

      let body = "";

      body = JSON.stringify({
        csrf: $csrf.getAttribute("content"),
        honeypot: $formHoneypot.checked,
        first_name: $formFirstname.value,
        last_name: $formLastname.value,
        email: $formEmail.value,
        // newsletter_signup: $formNewsletter.checked,
        post_id: $formPostId.value,
      });

      const submitText = $fromSubmit.textContent;

      // Disable the submit and change the text
      $fromSubmit.setAttribute("disabled", "disabled");
      $fromSubmit.classList.add("cursor-not-allowed");
      $fromSubmit.innerHTML = "Sending...";

      // Create a fetch post request
      fetch($landingBannerForm.getAttribute("action"), {
        method: "POST",
        body: body,
      })
        .then((response) => response.json())
        .then((response) => {
          // Check a response error exists
          if (response.error) {
            // Display an alert
            alert(response.error_message);

            // Enable the submit and restore the original text
            $fromSubmit.removeAttribute("disabled");
            $fromSubmit.classList.remove("cursor-not-allowed");
            $fromSubmit.innerHTML = submitText;
          } else {
            // Redirect the user
            alert(response.success_message);
            $fromSubmit.innerHTML = submitText;
          }
        })
        .catch((response) => {
          // Display an alert
          alert("Something went wrong, please try again later.");

          // Enable the submit and restore the original text
          $fromSubmit.removeAttribute("disabled");
          $fromSubmit.classList.remove("cursor-not-allowed");
          $fromSubmit.innerHTML = submitText;
        });
    });
  }
};

export default LandingBanner;
