export const close = ($container) => {
  const $trigger = $container.querySelector(".js-image-zoom-stop");
  const $zoomTrigger = $container.querySelector(".js-image-zoom-start");
  const $target = $container.querySelector(".js-image-zoom-target");

  // Add a content loaded event listener to the document
  $trigger.addEventListener("click", (event) => {
    $trigger.classList.add("hidden");
    $zoomTrigger.classList.remove("hidden");

    $target.style.transform = `scale(1)`;

    $target.classList.add("pointer-events-none");

    const body = document.getElementById("body");
    body.classList.remove("overflow-hidden");
  });
};

// Export the module
export default close;
