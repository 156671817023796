import { Swiper, Scrollbar, Pagination, Navigation } from "swiper";

Swiper.use([Scrollbar]);
Swiper.use([Pagination]);
Swiper.use([Navigation]);
/**
 * Initialize the containers.
 *
 * @param  {object}  $container  The brands.
 * @return {void}
 */
export const initializeCardSwiper = ($container) => {
  // Add a content loaded event listener to the document
  document.addEventListener("DOMContentLoaded", (event) => {
    // Create a new swiper instance
    // eslint-disable-next-line no-new
    const card = new Swiper(".js-card-swiper", {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.card-swiper-button-next',
        prevEl: '.card-swiper-button-prev',
      },
    });
  });
};

// Export the module
export default initializeCardSwiper;
