/*
  Functions for resetting select when view all is clicked
*/
export const resetCards = ($filter) => {
  const $events = document.querySelectorAll(".js-event-card");
  const select = document.querySelector(".js-events-filter-select");

  // Hide cards
  $events.forEach(card => {
    card.classList.remove("hidden");
  });

  select.value = "Filter by month";
};

// Export the module
export default resetCards;





